import React, { useEffect, useState } from 'react'
import LessonCard from './card'
import { GET } from '../../../utils/requests';
import { lesson_uri } from '../../../uri';
import {Skeleton} from "@chakra-ui/react";

const Lesson = () => {
    const [web, setWeb] = useState([]);
    const [tabao, setTabao] = useState([]);
    const [load, setLoad] = useState(false);

    // const Get  = async()=>{
    //     setLoad(true);
    //     try{
    //         const res = await GET(lesson_uri+'/user');
    //         setTabao(res.data.tabao);
    //         setWeb(res.data.website);
    //         setLoad(false);
    //     }catch(err){
    //         setLoad(false);
    //         console.log(err);
    //     }
    // }

    // useEffect(() => {
    //     Get();
    // }, []);

    
    
  return (
    <div className='text-black font-Roboto'>
        <div className='mt-10'>
            <h1 className='font-bold text-md uppercase text-xl'>Үнийн мэдээлэл</h1>
            <p className='text-sm text-gray-500'>Үнийн мэдээлэл</p>
        </div>
        <div className='mt-4 mx-2 text-lg'>
            <p>✅ 0-100гр ачаа 1000-2000₮ </p>
            <p>✅0-100гр ачаа 1000-2000₮ </p>
            <p>✅ 100гр-1кг ачаа 3000₮ </p>
            <p>✅ 1кг-2кг ачаа 6000₮ </p>
            <p>✅ Жижиг ачаа 1000₮-2000₮ /case,зүүлт, ээмэг, бөгж/ </p>
            <p>✅ 5кг дээш кг бодно </p>
            <p>✅ 0.01М³ дээш М³ бодно </p>
            <p>✅ М³ 350000₮ </p>
            <p>✅ Хөнгөн овортой ачаа овроор бодогдоно. /чихмэл тоглоом, хөнжил, дэр, куртик, латекс гудас гэх мэт / </p>
            <p>✅ Хайрцагтай гутал, пүүз, хүүхдийн гутал 6000-10000 овроосоо хамаарна.</p>
            {/* <div className='flex items-center gap-2'>
                <img className='rounded-[16px] h-12' src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMstl029WKE1BjWdWDMuBh0JARBb63n2oMlcu9upNPoA&s'/>
                <h1 className='text-sm text-gray-500'>Taobao аппликейшнтэй холбоотой заавар хичээлүүд</h1>
            </div>
            <div className='grid grid-cols-3 mt-2 gap-2'>
                {
                    load?
                    <>
                        <Skeleton className='h-20'/>
                        <Skeleton className='h-20'/>
                        <Skeleton className='h-20'/>
                    </>
                    :
                    <>
                        {
                            tabao.map((it, index) => {
                                return(
                                    <div>
                                        <LessonCard key={index} data={it}/>
                                    </div>
                                )
                            })
                        }
                    </>
                }
            </div> */}
        </div>
        {/* <div className='mt-4'>
            Эгшү карго заавар хичээлүүд
            <div className='grid grid-cols-3 mt-2 gap-2'>
                {
                    load?
                    <>
                        <Skeleton className='h-20'/>
                        <Skeleton className='h-20'/>
                        <Skeleton className='h-20'/>
                    </>
                    :
                    <>
                        {
                            web.map((it, index) => {
                                return(
                                    <div>
                                        <LessonCard key={index} data={it}/>
                                    </div>
                                )
                            })
                        }
                    </>
                }
            </div>
        </div> */}
    </div>
  )
}

export default Lesson