import React, { useContext, useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure,
    useToast,
    Spinner,
  } from '@chakra-ui/react'
import { RiDeleteBin5Line } from 'react-icons/ri';
import { GET } from '../../../../utils/requests';
import { getuseruri } from '../../../../uri';
import { AuthContext } from '../../../../context/auth';

const AddressDeleteModal = ({callback, data}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [load, setLoad] = useState(false);
    const toast = useToast();
    const {currentUser} = useContext(AuthContext);

    const handleCall = () => {
      callback();
    }

    const logoutFunction = async() => {
      setLoad(true);
      try{
        const res = await GET(getuseruri+`/address/delete?address_id=${data.id}&token=${currentUser}`);
        if(res.status === 200){
          handleCall();
          onClose();
          toast({title: 'Амжилттай',description: "Таны хаяг устлаа",status: 'success',duration: 5000,isClosable: true,});
        }
        setLoad(false);
      
      }catch(err){
        if(err.response.data.code == "P2003"){
          toast({title: 'Устгах боломжгүй',description: "Та энэ хаягаар ачаа хүргэлтэнд бүртгүүлсэн байна ! ",status: 'error',duration: 5000,isClosable: true,});
        }
        setLoad(false);
      }
    }


  return (
    <>
      <RiDeleteBin5Line onClick={onOpen} className='absolute bg-[#5151ca] rounded-md shadow-sm shadow-[#7878c2] p-1' size={32}/>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Та хаяг устгахдаа итгэлтэй байна уу ?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>

          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Болих
            </Button>
            {
              load?
              <Spinner/>
              :
              <Button onClick={logoutFunction} variant='ghost'>Тийм</Button>
            }
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default AddressDeleteModal