import React, { useContext, useEffect, useState } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import ProductCheckCard from './card';
import { TrackAddModal } from './Modal';
import { GET } from '../../../utils/requests';
import { getuseruri } from '../../../uri';
import { AuthContext } from '../../../context/auth';
import { TYPES } from '../../../utils/type';
import Paginations from '../../../components/Pagination';
import { Skeleton } from '@chakra-ui/react';
import { Empty } from 'antd';
import { Delivery } from './Modal/delivery';

const ProductCheck = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const [path, setPath] = useState(1);
  const {currentUser} = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({limit:20, all:'', totalPage:'',username:'', status:''});
  const [load, setLoad] = useState(false);
  const nav = useNavigate();

  const callback = () =>{
    Get();
  }

  const Get = async () => {
    setLoad(true);
    let st ;
    if(path === 1){
      st = TYPES.ALL
    }else if(path === 2){
      st = TYPES.REQUESTED
    }else if(path === 3){
      st = TYPES.EREEN
    }else if(path === 4){
      st = TYPES.IRSEN
    }else if(path === 5){
      st = TYPES.CONFIRM
    }
    try{
      const res = await GET(getuseruri+`/tracks?token=${currentUser}&page=${page}&status=${st}&limit=${pagination.limit}`);
      setData(res.data.data);
      setPagination({...pagination, all:res.data.all, totalPage:res.data.totalPage})
      setLoad(false);
    }catch(err){
      if(err.response.status === 444){
        nav("/login");
      }
    }
  }

  useEffect(() => {
    if(!currentUser){
      nav("/login");
      return
    }
    Get();
  }, [page, path])

  const changePage = (page) => {
    setPage(page)
  };

  

  if(currentUser){
    return (
      <div className='font-Roboto text-black mt-8 w-full mb-10'>
          <div>
              <h1 className='font-bold text-md uppercase text-xl'>Миний захиалгууд</h1>
              <p>My orders</p>
          </div>
  
          <div className='flex justify-between uppercase xs:text-xs md:text-md bg-custom-gradient rounded-xl p-1 w-full mt-2'>
            <div onClick={()=>setPath(1)} className={path === 1 ? 'rounded-xl md:p-2 xs:py-2 xs:px-1 bg-blue-400 text-gray-800 xs:w-full md:w-1/5 text-center cursor-pointer' : 'text-center cursor-pointer md:w-1/5 xs:w-full rounded-xl md:p-2 xs:py-2 xs:px-1  text-gray-500'}>
              <h1>Бүгд</h1>
            </div>
            <div onClick={()=>setPath(2)} className={path === 2 ? 'rounded-xl md:p-2 xs:py-2 xs:px-1 bg-blue-400 text-gray-800 xs:w-full md:w-1/5 text-center cursor-pointer' : 'text-center cursor-pointer md:w-1/5 xs:w-full rounded-xl md:p-2 xs:py-2 xs:px-1  text-gray-500'}>
              <h1>Бүртгүүлсэн</h1>
            </div>
            <div onClick={()=>setPath(3)} className={path === 3 ? 'rounded-xl md:p-2 xs:py-2 xs:px-1 bg-blue-400 text-gray-800 xs:w-full md:w-1/5 text-center cursor-pointer' : 'text-center cursor-pointer md:w-1/5 xs:w-full rounded-xl md:p-2 xs:py-2 xs:px-1  text-gray-500'}>
              <h1>Ачигдсан</h1>
            </div>
            <div onClick={()=>setPath(4)} className={path === 4 ? 'rounded-xl md:p-2 xs:py-2 xs:px-1 bg-blue-400 text-gray-800 xs:w-full md:w-1/5 text-center cursor-pointer' : 'text-center cursor-pointer md:w-1/5 xs:w-full rounded-xl md:p-2 xs:py-2 xs:px-1  text-gray-500'}>
              <h1>Ирсэн</h1>
            </div>
            <div onClick={()=>setPath(5)} className={path === 5 ? 'rounded-xl md:p-2 xs:py-2 xs:px-1 bg-blue-400 text-gray-800 xs:w-full md:w-1/5 text-center cursor-pointer' : 'text-center cursor-pointer md:w-1/5 xs:w-full rounded-xl md:p-2 xs:py-2 xs:px-1  text-gray-500'}>
              <h1>Авсан</h1>
            </div>
          </div>

          <div>
            {
              path === 4 &&
              <div>
                <Delivery prods={data.length} callback={callback}/>
              </div>
            }
          </div>
  
          <div className='mt-4'>
            {
              load?
              <div>
                <Skeleton className='h-20 w-full' rounded='xl'/>
                <Skeleton className='h-20 w-full mt-2' rounded='xl'/>
                <Skeleton className='h-20 w-full mt-2' rounded='xl'/>
              </div>
              :
              <div>
                {
                  data.map((it, index) => {
                    return(
                      <ProductCheckCard key={index} data={it} callback={callback}/>
                    )
                  })
                }
                {
                  data.length === 0 &&
                  <div className='text-white'>
                    <Empty description={
                      <span className='text-white'>
                        Хоосон байна
                      </span>
                    } />
                    </div>
                }
              </div>
            }
            <Paginations initialPage={1} total={pagination.totalPage} alldata={pagination.all} onChange={changePage}/> 
          </div>
          <div>
            <TrackAddModal callback={callback}/>
          </div>
      </div>
    )
  }else{
    <Navigate to="/login"/>
  }
}

export default ProductCheck
