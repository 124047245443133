import axios from "axios";
// import {decode} from 'jsonwebtoken';

// export const token = () => {
//     const mainKey = "Bayarsuren2782-*/_+===="
//     const token = jwt.sign({ mainKey: mainKey}, "egshu-secret-key", {
//         expiresIn: '10s'
//     });
//     console.log(token);
//     return token;
// }

const token = "keyKJHJDs5-*-sd-s+sds";

export const GET = async (url) => {
    try{
        const response = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response
    }catch(error){
        // if(error.response.status === 444){

        // }
        throw error;
    }
};


export const POST = async ({uri, data}) => {
    try{
        const response = await axios.post(uri, data,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        return response
    }catch(error){
        return error
    }
};

export const PATCH = async ({uri, data}) => {
    try{
        const response = await axios.patch(uri, data)
        return response
    }catch(error){
        return error
    }
};

export const DELETE = async ({uri, data}) => {
    try{
        const response = await axios.delete(uri, data)
        return response
    }catch(error){
        return error
    }
};

// const filetype = ['image/jpg','image/jpeg','image/png']

// export const IMAGE_UPLOAD = async ({file}) => {

//     const form = new FormData();
//     form.append('image', file);

//     try{
//         const response = await axios.post(utils_uri+`/img-upload` , form)
//         return response.data
//     }catch(error){
//         console.log(error);
//         toast.error("Дахин оролдоно уу !");
//         return ""
//     }

// };

// export const IMAGE_GET = async ({key}) => {
//     try{
//         const response = await axios.get(utils_uri+`/img-get?key=${key}`)
//         return response.data
//     }catch(error){
//         return error
//     }
// };
